<template>
  <div class="">
    <h2
      v-if="!title || !title.length"
      class="text-center lg:pt-4 font-medium text-2xl lg:text-3xl xl:text-4xl"
      aria-label="Our latest partner programs"
      tabindex="0"
    >
      Our latest partner programs
    </h2>
    <div class="relative mt-6 md:mt-10 xl:mt-16">
      <button
        v-if="isSmallView && partnersLength > 1"
        type="button"
        class="absolute z-10 inset-y-0 left-0 p-2 mr-0.5 hover:bg-white-05 hover:text-black focus:bg-white-05 focus:text-black"
        @click="slidePrev"
      >
        <Icon name="IconChevronLeft" class="text-2xl" title="Previous Slide" />
        <span class="sr-only">Previous Slide</span>
      </button>
      <client-only>
        <carousel
          ref="partnerCarousel"
          :wrap-around="!!isSmallView && partnersLength > 1"
          :breakpoints="breakpoints"
          class="gap-4 md:gap-6 xl:gap-8 justify-center"
          :class="carouselClass"
        >
          <slide v-for="(partner, i) in partners" :key="i">
            <div class="flex flex-col text-center px-2 pb-2 max-w-[400px]">
              <a
                :href="partner.url"
                :title="partner.name"
                class="flex justify-center items-center p-2 border border-transparent hover:border-brand-grey-400"
                target="partner.external && '_blank'"
              >
                <img
                  class="block"
                  :src="imgSource(partner.img.src)"
                  :alt="partner.img.alt"
                />
              </a>
            </div>
          </slide>
        </carousel>
      </client-only>
      <button
        v-if="isSmallView && partnersLength > 1"
        type="button"
        class="absolute z-10 inset-y-0 right-0 p-2 hover:bg-white-05 hover:text-black focus:bg-white-05 focus:text-black"
        @click="slideNext"
      >
        <Icon name="IconChevronRight" class="text-2xl" title="Next Slide" />
        <span class="sr-only">Next Slide</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

interface Partner {
  url: string
  name: string
  external: boolean
  img: {
    src: string
    alt: string
  }
}

const { getImgSrc } = useHelpers()
const viewport = useViewport()
const props = defineProps({
  partners: {
    type: Array as () => Partner[],
    required: true
  },
  title: {
    type: String,
    default: ''
  }
})
const partnerCarousel = ref(null)

const partnersLength = computed(() => {
  return props.partners.length
})

const isSmallView = computed(() => {
  return viewport.isLessThan('lg')
})

const carouselClass = computed(() => ({
  'mx-12': isSmallView.value,
  'flex items-center': !isSmallView.value
}))

// carousel breakpoint config to show carousel items in different breakpoints
// vue3-carousel breakpoints not accept md/lg instead has to use real breakpoint number
const breakpoints = {
  // md and up
  768: {
    itemsToShow: partnersLength.value > 2 ? 3 : 1
  },
  // lg and up
  1024: {
    itemsToShow: partnersLength.value
  }
}

function slideNext() {
  // @ts-ignore
  partnerCarousel.value.next()
}

function slidePrev() {
  // @ts-ignore
  partnerCarousel.value.prev()
}

function imgSource(imgSrc: string) {
  return getImgSrc(imgSrc)
}
</script>
